import React from "react";
import { Cta } from "../../components/Cta";
import FaqSection from "../../components/FaqSection";
import Layouts from "../../components/Layouts";
import Seo from "../../components/Seo";
import { ContactSection } from "../../components/Screens/Contact/ContactSection";
import { graphql } from "gatsby";

const ContactPageArabic = ({ data }) => {
	const page = data.page;

	return (
		<>
			<Seo title="تواصل معنا" />
			<Layouts lang={"ar"} dir="rtl">
				<ContactSection
					header={
						<div className="sm:w-9/12 xl:w-7/12">
							{page.contactAr.contactInfoAr.title}
						</div>
					}
					text={page.contactAr.contactInfoAr.text}
					lang="ar"
				/>
				<FaqSection
					header={page.contactAr.frequentlyAskedQuestionsAr.title}
					subHeader={page.contactAr.frequentlyAskedQuestionsAr.text}
					items={page.contactAr.frequentlyAskedQuestionsAr.questions}
				/>
				<Cta
					lang="ar"
					miniHeader="أول مقدم للخدمات المالية متناهية الصغر في الشرق الأوسط"
					header="مهمتنا تمكين الوصول الى الإمكانيات المالية الرقمية"
					subHeader="كاشات هي أول خطوة للشمول المالي للمصريين الغير متعاملين مع البنوك عن طريق توفير تمويلات انتاجية قصيرة المدى وسريعة"
				/>
			</Layouts>
		</>
	);
};
export default ContactPageArabic;
export const ContactArabicQuery = graphql`
	query ContactArabic {
		page: wpPage(databaseId: { eq: 384 }) {
			title
			contactAr {
				contactInfoAr {
					title
					text
					phone
					email
				}
				frequentlyAskedQuestionsAr {
					title
					text
					questions {
						question
						answer
					}
				}
			}
		}
	}
`;
